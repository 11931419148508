.container {
  background-color: #f8f9fa;
}

.ff-satisfy {
  font-family: "Satisfy", cursive;
}

.ff-lato {
  font-weight: 900;
  font-family: "lato", sans-serif;
}

.ff-sans {
  font-weight: 900;
  font-family: "DM Sans", sans-serif;
}

.est {
  font-weight: 900;
  font-family: "DM Sans", sans-serif;
  font-size: 1.2em;
}

.logo {
  max-height: 256px;
}

.oc-title {
  font-family: "Satisfy", cursive;
  font-size: 1.5em;
}
