html {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

#__next {
  flex: 1;
  display: flex;
  flex-direction: column;
}

:root {
  scroll-behavior: auto !important;
}

::-webkit-scrollbar {
  width: 0.8rem;
}
::-webkit-scrollbar-track {
  background-color: #f8f9fa;
}
::-webkit-scrollbar-thumb {
  background-color: #6c757d;
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5b6369;
}
